import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import { DATA } from "../../components/data"

const Page = () => {
  const { posts } = DATA

  return (
    <Layout>
      <SEO title={`Blog`} />

      <div className="post-list">
        <div className="container">
          <div className="row">
            <h1 className="offer-item-title">Wpisy na blogu Balicka Clinic</h1>

            {posts.map((post, index) => (
              <>
                <div key={index} className="col-md-12 col-xs-12">
                  <div>
                    <a href={`/blog/${post.name}`}>
                      <h2 className="post-item-title">{post.title}</h2>
                      <p className="post-item-date">{post.date}</p>
                      <div className="separator-line-horizontal"></div>
                      <p className="post-item-short-description">
                        {post.short_description}
                      </p>
                    </a>
                  </div>
                  {posts[index + 1] && (
                    <div className="separator-middleline-horizontal"></div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
